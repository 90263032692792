import _ from 'lodash';
import * as ConfigExperimentsAPI from '../../lib/config-experiments';
import { buildSupportLink } from './support-email.helper';
import * as Analytics from '../../lib/analytics';
import { IOutsideElementClick } from '../../directives/outside-element-click.directive';
import { IConfigExperiments, IConfigObj, IOrganization } from '../../lib/types';
import { UserExperimentsStorage } from '../../lib/storage-user-experiments';
import { ToggleModel } from '../../lib/model/model-toggle';
import { normalizeHandbook } from '../../lib/config-handbook';

const module = angular.module('42.main.navbar', []);

export interface NavMultiCheckDropdownItem {
    id: string;
    label: string;
    selected?: boolean;
    url?: string;
}

export interface NavMultiCheckDropdownModel {
    list: NavMultiCheckDropdownItem[];
    checkbox?: boolean;
    label: string;
    onItemClick: (item: NavMultiCheckDropdownItem) => void;
}

interface NavMultiCheckDropdownDirectiveScope extends angular.IScope {
    model: NavMultiCheckDropdownModel;
    toggle: ToggleModel;
    onClick: (item: NavMultiCheckDropdownItem) => void;
    onItemClick: ($event: Event, item: NavMultiCheckDropdownItem) => void;
}

module.directive('navMultiCheckDropdown', [
    'OutsideElementClick',
    function NavMultiCheckDropdownDirective(
        OutsideElementClick: IOutsideElementClick,
    ): angular.IDirective<NavMultiCheckDropdownDirectiveScope> {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
                <div class="nav-multi-check-dropdown">
                    <div class="nav-multi-check-dropdown-header" ng-class="{'opened': toggle.isOpen }" ng-click="toggle.toggle()">
                        <i ng-if="model.icon" class="title-icon {{ model.icon }}"></i>
                        <div class="nav-multi-check-dropdown-header-title">{{ model.label }}</div>
                        <i class="icon-dropdown-toggle"></i>
                    </div>
                    <div class="nav-multi-check-dropdown-options" ng-if="toggle.isOpen">
                        <div class="nav-multi-check-dropdown-item"
                            ng-repeat="item in model.list"
                            ng-click="onItemClick($event, item)"
                            ng-class="{'selected': item.selected }">
                            <div class="nav-multi-check-dropdown-item-icon" ng-if="item.icon">
                                <i class="{{ item.icon }}"></i>
                            </div>
                            <div class="checkbox-container" ng-if="model.checkbox">
                                <input type="checkbox" ng-model="item.selected">
                            </div>
                            <div class="nav-multi-check-dropdown-item-title">{{ item.label }}</div>
                        </div>
                    </div>
                </div>
            `,
            link: function MultiCheckDropdownLink($scope, $element) {
                $scope.toggle = new ToggleModel();

                let outsideElementClickCleanup = () => {};
                $scope.$watch('toggle.isOpen', (isOpen: undefined | boolean) => {
                    outsideElementClickCleanup();
                    if (!isOpen) return;
                    outsideElementClickCleanup = OutsideElementClick(
                        $scope,
                        $element,
                        () => void $scope.toggle.close(),
                    );
                });

                $scope.onItemClick = ($event, item) => {
                    $event.preventDefault();
                    $event.stopImmediatePropagation();
                    $scope.model.onItemClick(item);
                };
            },
        };
    },
]);

module.directive('mainNavbar', [
    '$q',
    '$rootScope',
    '$window',
    'CONFIG',
    'OutsideElementClick',
    function MainNavBarDirectiveFn(
        $q: angular.IQService,
        $rootScope: angular.IRootScopeService,
        $window: angular.IWindowService,
        CONFIG: IConfigObj,
        OutsideElementClick: IOutsideElementClick,
    ): angular.IDirective<
        angular.IScope & {
            organization: IOrganization | undefined;
            mobileMenu: { enabled: boolean; panel: ToggleModel };
            experimentsMenu: undefined | NavMultiCheckDropdownModel;
            onHandbookClick: () => void;
            windowSize: { width: number; isBig: boolean; isSmall: boolean };
            handbook?: NavMultiCheckDropdownModel | undefined;
            links: {
                support?: string | undefined;
            };
        }
    > {
        return {
            restrict: 'E',
            scope: true,
            replace: true,
            template: `
                <nav class="top-bar collapses" ng-class="{small: mobileMenu.enabled}">
                    <div class="collapse-header">
                        <a class="header-logo" ng-href="{{ routes.overview.url }}"><div class="logo"></div></a>
                        <i ng-click="mobileMenu.panel.toggle()" ng-hide="!mobileMenu.enabled" ng-class="{active: mobileMenu.panel.isOpen}" class="icon-menu"></i>
                    </div>
                    <ul ng-if="initialized" ng-click="mobileMenu.panel.toggle()" class="ng-cloak report-select">
                        <div ng-if="$parent.activeRoute.group != 'reporting'" ng-class="{small: mobileMenu.enabled, hide: mobileMenu.enabled && !mobileMenu.panel.isOpen}" class="page-select collapses">
                            <route model="routes.overview"></route>
                            <route model="routes.metrics" ng-if="pages.metrics"></route>
                            <route model="routes.structure" ng-if="pages.structure"></route>
                            <route model="routes.customers" ng-if="pages.customers"></route>
                            <route model="routes.transactions" ng-if="pages.transactions"></route>
                            <route model="routes.grid"></route>
                            <route model="routes.inventory" ng-if="pages.inventory"></route>
                            <route model="routes.map" ng-if="pages.map"></route>
                            <route model="routes.chart" ng-if="pages.chart"></route>
                            <route model="routes.ads" ng-if="pages.ads"></route>
                            <route model="routes.reportingReports" ng-if="pages.reporting"></route>
                        </div>
                        <div ng-if="$parent.activeRoute.group == 'reporting'" ng-class="{small: mobileMenu.enabled, hide: mobileMenu.enabled && !mobileMenu.panel.isOpen}" class="page-select collapses">
                            <li><a ng-href="{{ routes.overview.url }}" target="{{ routes.overview.href && '_blank'}}"><i class="icon-left"></i><span class="route-label">Dashboard</span></a></li>
                            <route model="routes.reportingReports" submenu='true'></route>
                            <route model="routes.reportingSchedules"></route>
                        </div>
                    </ul>
                    <div ng-class="{small: mobileMenu.enabled, hide: mobileMenu.enabled && !mobileMenu.panel.isOpen}", ng-click="mobileMenu.panel.close()" class="nav-support right collapses" ng-cloak="ng-cloak">
                        <div class="support" ng-show="initialized && experimentsMenu && !mobileMenu.enabled">
                            <nav-multi-check-dropdown model="experimentsMenu"></nav-multi-check-dropdown>
                        </div>
                        <div class="support links" ng-if="initialized && handbook">
                            <a ng-if="handbook.list.length === 1"
                                ng-href="{{ handbook.list[0].url }}"
                                ng-click="onHandbookClick()"
                                target="_blank">
                                <i class="icon-book"></i>
                                <span>Handbook</span>
                            </a>
                            <nav-multi-check-dropdown
                                ng-if="handbook.list.length > 1"
                                model="handbook">
                            </nav-multi-check-dropdown>
                        </div>
                        <div class="support links">
                            <a ng-if="!links.support" ng-href="mailto:support@42technologies.com?subject=Support request">
                                <i class="support-icon icon-paper-plane"></i>
                                <span>Support</span>
                                </a>
                            <a ng-if="links.support" ng-href="{{ links.support }}" target="_blank">
                                <i class="support-icon icon-paper-plane"></i>
                                    <span>Support</span>
                                </a>
                        </div>
                        <status-message-bar-button></status-message-bar-button>
                    </div>
                </nav>
            `,
            link: function MainBarDirectiveLink(scope, element) {
                const initializedWatcher = $rootScope.$watch('initialized', (initialized: boolean) => {
                    if (!initialized || scope.experimentsMenu) return;
                    void $q.when(createNavBarDropdownModel()).then(model => {
                        scope.experimentsMenu = model;
                        initializedWatcher();
                    });
                });
                scope.$on('$destroy', () => initializedWatcher());

                scope.mobileMenu = {
                    enabled: false,
                    panel: new ToggleModel(),
                };
                OutsideElementClick(scope, element, () => void scope.mobileMenu.panel.close());
                scope.$watch(
                    () => $window.innerWidth,
                    (width: number) => void (scope.mobileMenu.enabled = width <= 768),
                );

                scope.links = {};
                scope.$watch('organization', () => {
                    scope.handbook = (() => {
                        const handbook = normalizeHandbook(scope.organization?.handbook);
                        if (!handbook) return;
                        return {
                            list: handbook.map((item, index) => {
                                let { id, label, url } = item;
                                const itemNumber = index + 1;
                                label ??= `Doc #${itemNumber} `;
                                id ??= `${itemNumber}`;

                                return { id, label, url, icon: 'icon-doc-text' };
                            }),
                            checkbox: false,
                            icon: 'icon-book',
                            label: 'Handbook',
                            onItemClick: item => {
                                const doc = scope.handbook?.list.find(doc => doc.id === item.id);

                                if (doc) {
                                    scope.onHandbookClick();
                                    $window.open(doc.url, '_blank');
                                }
                            },
                        };
                    })();

                    scope.links.support = buildSupportLink(CONFIG, scope.organization);
                });
                scope.onHandbookClick = () => Analytics.track(Analytics.EVENTS.USER_NAVIGATED_HANDBOOK);
            },
        };
    },
]);

const createNavBarDropdownModel = async (): Promise<undefined | NavMultiCheckDropdownModel> => {
    const experiments = await ConfigExperimentsAPI.fetch();

    const updateExperimentsStorage = async (experiments: Partial<IConfigExperiments>) => {
        const localStorageExperiments = UserExperimentsStorage.get();
        const update = {
            ...localStorageExperiments,
            ...experiments,
        };
        await UserExperimentsStorage.set(update);
        window.location.reload();
    };

    return {
        label: 'Experiments',
        list: [
            {
                id: 'enableSidebar',
                label: 'Sidebar',
                selected: Boolean(experiments.sidebar),
            },
        ],
        checkbox: true,
        onItemClick: item => {
            void updateExperimentsStorage({ [item.id]: !item.selected });
        },
    };
};

export default module;
